<template>
    <div
        class="loader ease-in-out rounded-full border-4 border-t-4 border-gray-100 h-16 w-16 mx-auto"
    ></div>
</template>

<script>
export default {
    name: 'Loader',
}
</script>
