import { createApp } from 'vue'
import { createStore } from 'vuex'
import App from './App.vue'
import router from './router'
import '@/Firebase'
import '@/assets/styles/main.css'
import VCalendar from 'v-calendar'

const store = createStore({
    state() {
        return {
            uid: null,
        }
    },
    mutations: {
        setUid(state, uid) {
            state.uid = uid
        },
    },
})

createApp(App)
    .use(VCalendar)
    .use(router)
    .use(store)
    .mount('#app')
