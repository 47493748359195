import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/auth',
        name: 'Auth',
        component: () => import('@/views/Auth'),
    },
    {
        path: '/',
        name: 'Dashboard',
        component: () => import('@/views/Dashboard'),
    },

    {
        path: '/currency/add',
        name: 'AddCurrency',
        component: () => import('@/views/currency/AddCurrency'),
    },
    {
        path: '/:id',
        name: 'ViewCurrency',
        component: () => import('@/views/currency/ViewCurrency'),
    },
    {
        path: '/signout',
        name: 'SignOut',
        component: () => import('@/views/SignOut'),
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: 'text-teal-700',
    linkExactActiveClass: 'text-teal-700',
})

export default router
