import firebase from 'firebase/app'
import 'firebase/firestore'

const settings = { timestampsInSnapshots: true }

const config = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
}
const firebaseApp = firebase.initializeApp(config)
const db = firebaseApp.firestore(settings)

export const firebaseAssets = db.collection('assets')
export const firebaseCurrencies = db.collection('currencies')
export const firebaseTransactions = db.collection('transactions')
