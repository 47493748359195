<template>
    <router-view v-slot="{ Component }">
        <component v-if="loaded" :is="Component" />
        <div
            v-else
            class="dark:bg-gray-800 min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8"
        >
            <Loader />
        </div>
    </router-view>
</template>
<script>
import firebase from 'firebase/app'
import 'firebase/auth'

import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { reactive, toRefs } from 'vue'

import Loader from '@/components/Loader'

import errorHandler from '@/helpers/errorHandler'

export default {
    components: {
        Loader,
    },

    setup() {
        const state = reactive({
            loaded: false,
        })
        const router = useRouter()
        const store = useStore()

        const observeAuth = async () => {
            try {
                await firebase.auth().onAuthStateChanged(user => {
                    state.loaded = true
                    if (user) {
                        store.commit('setUid', user.uid)
                    } else {
                        router.push({ name: 'Auth' })
                    }
                })
            } catch (error) {
                errorHandler(error)
            }
        }

        observeAuth()

        return {
            ...toRefs(state),
        }
    },
}
</script>
<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}
</style>
